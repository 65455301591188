<template>
	<v-app>
		<v-img
			id="background"
			:src="require('@/assets/background.png')"
		/>
		
		<v-app-bar
			height="80"
			elevation="1"
			color="primary"
			fixed
		>
			<v-spacer
				style="width: 0"
			>
				<img
					v-if="!useMobileView"
					class="logo"
					:src="require(`@/assets/logo_white.png`)"
					height="60"
				/>
				<img
					v-if="useMobileView"
					class="logo"
					:src="require(`@/assets/logo_white_noText.png`)"
					height="60"
				/>
			</v-spacer>
			<v-toolbar-title
				class="portal-header toolbar-title light--text"
			>
				Unsere digitalen Softwarelösungen
			</v-toolbar-title>
			<v-spacer/>
		</v-app-bar>
		
		<v-main
			style="margin-top: 80px"
		>
			<v-container
				style="max-width: 1400px"
				class="pa-4"
			>
				<v-row>
					<v-col
						v-for="(element, index) of items"
						:key="index"
						md="4"
						cols="12"
					>
						<v-card
							class="content-card"
						>
							<v-img
								v-if="element.image"
								:src="require('@/assets/' + element.image)"
								height="160"
							/>
							<v-card-title
								class="portal-link__title"
							>
								{{ element.title }}
							</v-card-title>
							<v-card-text
								class="portal-link__description"
							>
								{{ element.description }}
							</v-card-text>
							<v-spacer/>
							<v-card-actions>
								<v-spacer/>
								<div
									v-if="element.items"
								>
									<v-btn
										text
										color="error"
										@click="detailsContent = element;detailsDialog = true"
									>
										Mehr erfahren
									</v-btn>
								</div>
								<div
									v-else
								>
									<v-btn
										text
										color="error"
										@click="link(element.link)"
									>
										Zur Demo
										<v-icon class="ml-2">mdi-open-in-new</v-icon>
									</v-btn>
									<v-btn
										v-show="element.file"
										text
										color="error"
										@click="link(element.file)"
									>
										<!-- <v-icon large>mdi-file-pdf-box</v-icon> -->
										PDF laden
										<v-icon class="ml-2">mdi-download</v-icon>
									</v-btn>
								</div>
								<v-spacer/>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
				
				<v-dialog
					v-model="detailsDialog"
					max-width="800"
				>
					<v-card>
						<div class="portal-header px-6 py-4 primary--text">
							{{ detailsContent?.title }}
						</div>
						
						<v-card-text
							class="details"
							v-for="element of detailsContent?.items"
							:key="element.title"
							:disabled="element.disabled"
						>
							<div
								class="mr-4"
							>
								<div
									class="details__title"
								>
									{{ element.title }}
								</div>
								<div
									class="details__description"
								>
									{{ element.description }}
								</div>
							</div>
							<v-spacer/>
							<v-btn
								class="mr-2"
								fab
								small
								:disabled="element.disabled"
								color="error"
								@click="link(element.link)"
							>
								<v-icon>mdi-open-in-new</v-icon>
							</v-btn>
							<v-btn
								v-show="element.file"
								fab
								small
								:disabled="element.disabled"
								color="error"
								@click="link(element.file)"
							>
								<v-icon>mdi-download</v-icon>
							</v-btn>
						</v-card-text>
						<v-card-actions>
							<v-spacer/>
							<v-btn
								text
								color="error"
								@click="detailsDialog = false"
							>
								Schließen
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-container>
		</v-main>
		
		<v-footer
			color="#ececec"
			padless
		>
			<v-row
				justify="center"
				no-gutters
			>
				<v-btn
					v-for="element in footerLinks"
					:key="element.text"
					color="dark"
					text
					rounded
					class="my-2"
					@click="link(element.link)"
				>
					{{ element.text }}
				</v-btn>
			</v-row>
		</v-footer>
	</v-app>
</template>

<script>
export default
{
	name: 'App',
	
	created ()
	{
		document.title = 'G+B Portale';
		window.addEventListener('resize', this.updateUseMobileView);
		this.updateUseMobileView();
	},
	
	methods:
	{
		link (link)
		{
			if (!link)
			{
				return;
			}
			
			window.open(link, '_blank');
		},
		updateUseMobileView ()
		{
			this.useMobileView = window.innerWidth < 960;
		}
	},
	
	data: () =>
	(
		{
			detailsDialog: false,
			detailsContent: null,
			items:
			[
				{
					image: 'BUP_Home.png',
					title: 'Streaming Portal',
					description: 'Landingpage in Ihrem CI mit integriertem Stream sowie extra Funktionen: Agenda + Q&A + Chat und mehr',
					items:
					[
						{
							title: 'Streaming Portal Basic',
							description: 'Landingpage in Ihrem CI mit integriertem Stream, sowie unsere Basic Funktionen: Agenda + Q&A + Chat',
							link: 'https://basic-portal.eventvote.de',
							file: 'pdfs/G+B - Onepager - Basic Portal.pdf'
						},
						{
							title: 'Streaming Portal Business',
							description: 'Mehr als Basic? Basic Funktionen + Speaker + Live-Voting + Mediathek + Like Funktion',
							link: 'https://business-portal.eventvote.de',
							file: 'pdfs/G+B - Onepager - Business Portal.pdf'
						}
					]
				},
				{
					image: 'event-app-tablet_960.webp',
					title: 'Event App',
					description: 'Eine App für alle Veranstaltungen',
					link: 'https://eventapp.eventvote.de',
					file: 'pdfs/G+B - Onepager - Event App.pdf'
				},
				{
					image: 'BSP_Agenda.png',
					title: 'Wahlen',
					description: 'Online Wahlen durchführen',
					link: 'https://wahlen.eventvote.de/',
				},
				{
					image: 'teilnehmermanagement-1.webp',
					title: 'Teilnehmermanagement',
					description: 'Verwalten und bearbeiten Sie Ihre Gästedaten für Veranstaltungen künftig problemlos mit unserem TLM',
					link: 'https://demo.gb-registration.de/',
					file: 'pdfs/G+B - Onepager - Teilnehmermanagement.pdf'
				},
				{
					image: 'gb-sales-presenter-2025.webp',
					title: 'Digital Presenter',
					description: 'Ihre interaktive Vertriebs- und Präsentationslösung',
					link: 'https://presenter-demo.gb-interactive.com',
					file: 'pdfs/G+B - Onepager - Digital-Presenter.pdf'
				},
				{
					image: '02_Slidecenter.png.png',
					title: 'Slide Center',
					description: 'Online Verwaltung und Organisation Ihrer Präsentationen - live und digital',
					link: 'https://slidecenter.eventvote.de/user/login',
					file: 'pdfs/G+B - Onepager - Slide-Center.pdf'
				}
			],
			footerLinks:
			[
				{
					text: 'Impressum',
					link: 'https://www.gb-mediensysteme.de/impressum/'
				},
				{
					text: 'Datenschutz',
					link: 'https://www.gb-mediensysteme.de/datenschutzerklaerung/'
				},
				{
					text: 'GAHRENS + BATTERMANN GmbH & Co. KG',
					link: 'https://www.gb-mediensysteme.de/'
				}
			],
			useMobileView: false
		}
	)
};
</script>

<style lang="sass">
$fontWeights: ('L': 300, 'R': 400, 'M': 500, 'B': 700)
@each $key, $fontWeight in $fontWeights
	@font-face
		font-family: 'Ubuntu'
		src: url('fonts/Ubuntu/Ubuntu-' + $key + '.ttf')
		font-weight: $fontWeight
	@font-face
		font-family: 'Ubuntu'
		src: url('fonts/Ubuntu/Ubuntu-' + $key + 'I.ttf')
		font-weight: $fontWeight
		font-style: italic

*
	font-family: 'Ubuntu', Helvetica, sans-serif
	//word-break: break-word

// hide scrollbar if not needed
html
	overflow-y: auto

// vuetify overrides
.theme--light.v-card
	.v-card__text, .v-card__subtitle
		color: unset

// new design styles
.v-card, .v-window, .v-menu__content, .v-stepper
	border-radius: 16px !important

.v-stepper__label
	text-align: center !important

.v-stepper__wrapper
	overflow: visible !important

.v-card__title
	word-break: break-word
	
#background
	position: fixed
	min-height: 100vh
	min-width: 100vw
	scale: 1.1
	filter: blur(7.5px) brightness(0.75)

.portal-header
	font-size: 1.8rem
	font-weight: 500

.portal-info
	font-size: 1.25rem

.content-card
	min-height: 360px
	display: flex
	flex-direction: column
	.v-image
		flex-grow: 0
		overflow: hidden
		.v-image__image
			filter: blur(4px)
			scale: 1.1
	
.portal-link
	display: flex
	align-items: center
	
	&__title
		color: var(--v-secondary-base)
		font-size: 1.8rem
		@media screen and (max-width: 640px)
			font-size: 1.6rem
		
	&__description
		color: var(--v-secondary-base)
		font-size: 1.125rem

.details
	display: flex
	align-items: center
	
	&__title
		color: var(--v-primary-base)
		line-height: 2rem
		padding: 16px 0px
		font-size: 1.8rem
	
	&__description
		color: #0094d5
		font-size: 1.25rem
		
.details-link + .details-link
	margin-top: 1rem

.toolbar-title
	max-width: 70%
	
@media screen and (max-width: 640px)
	.toolbar-title
		max-width: 60%
		font-size: 1.25rem
		white-space: normal
</style>