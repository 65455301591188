import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify(
	{
		icons:
		{
			iconfont: 'mdi'
		},
		theme:
		{
			options:
			{
				// setting this option exposes the color variables so that we can use them 
				// in style blocks like this:
				// color: var(--v-primary-base);
				// where 'primary' takes the color value defined as 'primary' and 'base'
				// refers to the currently selected theme
				// there are generated variations based on the theme colors, inspect html
				// element to see all available variables
				customProperties: true
			},
			themes:
			{
				light:
				{
					primary: '#001C31',
					secondary: '#0094D5',
					tertiary: '#003761',
					success: '#38A962',
					error: '#E30054',
					info: '#0094D5',
					link: '#0094D5',
					
					dark: '#000000',
					light: '#ffffff',
					background: '#ffffff'
				}
			}
		}
	}
);